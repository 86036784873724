var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"users","fluid":"","tag":"section"}},[_c('alert-notification',{attrs:{"message":_vm.alert}}),_c('create-driver-form',{on:{"success":_vm.refreshData,"fail":_vm.fail},model:{value:(_vm.createDriverForm),callback:function ($$v) {_vm.createDriverForm=$$v},expression:"createDriverForm"}}),(_vm.$can('create', 'Admin.Location.User'))?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.createDriverForm,"loading":_vm.createDriverForm},on:{"click":function($event){$event.stopPropagation();_vm.createDriverForm=true}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v(" mdi-account-plus ")]),_vm._v(" "+_vm._s(_vm.$t('admin.sub.users.button.driver'))+" ")],1)],1):_vm._e(),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('admin.sub.users.title'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('admin.sub.users.table.search'),"single-line":"","hide-details":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"options":_vm.table.options,"items":_vm.users,"search":_vm.table.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatRole(item.role)))])]}},{key:"item.setting.vehicle",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('userSettings.settings.vehicle.values.' + String(item.setting.vehicle) + '.name')))])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('a',{attrs:{"href":'tel:' + _vm.phoneInt(item.phoneNumber),"target":"_blank"}},[_vm._v(_vm._s(_vm.displayPhone(item.phoneNumber)))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can('update', 'Admin.Location.User'))?_c('span',[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){_vm.updateUser=item}}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(_vm.$can('update', 'Admin.Location.User'))?_c('span',{staticClass:"ml-2"},[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.generateDriverPassword(item)}}},[_vm._v(" mdi-lock-reset ")])],1):_vm._e(),(_vm.$can('delete', 'Admin.Location.User'))?_c('span',{staticClass:"ml-2"},[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.deleteDriver(item)}}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)})],1),(_vm.updateUser !== undefined)?_c('edit-user-detail-form',{attrs:{"user":_vm.updateUser},on:{"success":_vm.success,"fail":_vm.fail}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }