<template>
  <v-container id="users" fluid tag="section">
    <alert-notification :message="alert" />
    <create-driver-form v-model="createDriverForm" @success="refreshData" @fail="fail" />
    <div v-if="$can('create', 'Admin.Location.User')" class="text-right">
      <v-btn color="secondary" :disabled="createDriverForm" :loading="createDriverForm" @click.stop="createDriverForm=true">
        <v-icon class="pr-2">
          mdi-account-plus
        </v-icon>
        {{ $t('admin.sub.users.button.driver') }}
      </v-btn>
    </div>
    <v-card>
      <v-card-title>
        {{ $t('admin.sub.users.title') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('admin.sub.users.table.search')" single-line hide-details />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="users" :search="table.search" :loading="loading">
        <template #[`item.role`]="{ item }">
          <span>{{ formatRole(item.role) }}</span>
        </template>
        <template #[`item.setting.vehicle`]="{ item }">
          <span>{{ $t('userSettings.settings.vehicle.values.' + String(item.setting.vehicle) + '.name') }}</span>
        </template>
        <template #[`item.phoneNumber`]="{ item }">
          <span><a :href="'tel:' + phoneInt(item.phoneNumber)" target="_blank">{{ displayPhone(item.phoneNumber) }}</a></span>
        </template>
        <template #[`item.createdAt`]="{ item }">
          <span>{{ formatDate(item.createdAt) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <span v-if="$can('update', 'Admin.Location.User')">
            <v-icon medium @click="updateUser=item">
              mdi-pencil
            </v-icon>
          </span>
          <span v-if="$can('update', 'Admin.Location.User')" class="ml-2">
            <v-icon medium @click="generateDriverPassword(item)">
              mdi-lock-reset
            </v-icon>
          </span>
          <span v-if="$can('delete', 'Admin.Location.User')" class="ml-2">
            <v-icon medium @click="deleteDriver(item)">
              mdi-delete
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card>
    <edit-user-detail-form v-if="updateUser !== undefined" :user="updateUser" @success="success" @fail="fail" />
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { handleRequestError } from '@/services/common/Http';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import CreateDriverForm from '@/views/forms/user/CreateDriverForm';
  import EditUserDetailForm from '@/views/forms/user/EditUserDetailForm';

  export default {
    name: 'Users',
    components: { AlertNotification, CreateDriverForm, EditUserDetailForm },
    data () {
      return {
        alert: '',
        createDriverForm: false,
        updateUser: undefined,
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('admin.sub.users.table.column.username'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'username'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.firstName'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'firstName'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.lastName'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'lastName'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.role'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'role'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.phone'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'phoneNumber'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.vehicle'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'setting.vehicle'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.createdAt'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'createdAt'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['createdAt'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        users: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      displayPhone: displayPhone,
      phoneInt: phoneInt,
      formatRole (role) {
        return this.$i18n.t('admin.sub.users.role.' + role);
      },
      formatDate (date) {
        return DateTime.fromISO(date, { setZone: true }).toLocaleString({ locale: 'fr-fr' });
      },
      async generateDriverPassword (user) {
        if (confirm(this.$i18n.t('admin.sub.users.form.updateDriverPassword.confirmation', { name: user.username }))) {
          this.loading = true;
          try {
            const response = await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'user/password/', { userId: user.id });
            if (response.data.password !== undefined) {
              alert('Le nouveau mot de passe de ' + String(user.username) + ' est ' + String(response.data.password));
            }
            this.alert = '';
          } catch (error) {
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;
          await this.refreshData();
        }
      },
      async updateDriver (user) {
        if (confirm(this.$i18n.t('admin.sub.users.form.deleteDriver.confirmation', { name: user.firstName }))) {
          this.loading = true;
          try {
            await this.$http.delete(process.env.VUE_APP_BASE_API_URL + 'user/driver/?id=' + String(user.id));
            this.alert = '';
          } catch (error) {
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;
          await this.refreshData();
        }
      },
      async deleteDriver (user) {
        if (confirm(this.$i18n.t('admin.sub.users.form.deleteDriver.confirmation', { name: user.firstName }))) {
          this.loading = true;
          try {
            await this.$http.delete(process.env.VUE_APP_BASE_API_URL + 'user/driver/?id=' + String(user.id));
            this.alert = '';
          } catch (error) {
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;
          await this.refreshData();
        }
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'users/staff');
          if (response.data !== undefined && response.data.users !== undefined) {
            this.users = response.data.users;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async success () {
        this.updateUser = undefined;
        await this.refreshData();
      },
      async fail (error, message) {
        this.updateUser = undefined;
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
