<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="80%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('admin.sub.users.form.createDriver.title') }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('admin.sub.users.form.createDriver.subtitle') }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && createDriver()">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="firstName" :counter="firstNameMaxLength" required :rules="firstNameRules"
                              :label="$t('admin.sub.users.form.createDriver.input.firstName.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="lastName" :counter="lastNameMaxLength" :rules="lastNameRules"
                              :label="$t('admin.sub.users.form.createDriver.input.lastName.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="username" :counter="usernameMaxLength" required :rules="usernameRules" :label="$t('admin.sub.users.form.createDriver.input.username.label')" :clearable="true"
                              @focusin="autoCompleteUsername()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="phone" :rules="phoneRules" required :label="$t('admin.sub.users.form.createDriver.input.phone.label')" :clearable="true" />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('admin.sub.users.form.createDriver.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && createDriver()">
              {{ $t('admin.sub.users.form.createDriver.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { isValidPhoneNumber } from '@/util/ValidationUtil';

  export default {
    name: 'CreateDriverForm',
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        dialog: false,
        firstName: '',
        firstNameMinLength: 1,
        firstNameMaxLength: 30,
        firstNameRules: [
          v => !!v || this.$t('admin.sub.users.form.createDriver.input.firstName.rule.required'),
          v => (v && v.length >= this.firstNameMinLength) || this.$t('admin.sub.users.form.createDriver.input.firstName.rule.minLength', { count: this.firstNameMinLength }),
          v => (v && v.length <= this.firstNameMaxLength) || this.$t('admin.sub.users.form.createDriver.input.firstName.rule.maxLength', { count: this.firstNameMaxLength })
        ],
        lastName: '',
        lastNameMinLength: 1,
        lastNameMaxLength: 30,
        lastNameRules: [
          v => !v || (v && v.length >= this.lastNameMinLength) || this.$t('admin.sub.users.form.createDriver.input.lastName.rule.minLength', { count: this.lastNameMinLength }),
          v => !v || (v && v.length <= this.lastNameMaxLength) || this.$t('admin.sub.users.form.createDriver.input.lastName.rule.maxLength', { count: this.lastNameMaxLength })
        ],
        username: '',
        usernameMinLength: 1,
        usernameMaxLength: 80,
        usernameRules: [
          v => !!v || this.$t('admin.sub.users.form.createDriver.input.username.rule.required'),
          v => (v && v.length >= this.usernameMinLength) || this.$t('admin.sub.users.form.createDriver.input.username.rule.minLength', { count: this.usernameMinLength }),
          v => (v && v.length <= this.usernameMaxLength) || this.$t('admin.sub.users.form.createDriver.input.username.rule.maxLength', { count: this.usernameMaxLength })
        ],
        phone: '',
        phoneMinLength: 10,
        phoneMaxLength: 30,
        phoneRules: [
          v => !!v || this.$t('admin.sub.users.form.createDriver.input.phone.rule.required'),
          v => (v && v.length >= this.phoneMinLength) || this.$t('admin.sub.users.form.createDriver.input.phone.rule.minLength', { count: this.phoneMinLength }),
          v => (v && v.length <= this.phoneMaxLength) || this.$t('admin.sub.users.form.createDriver.input.phone.rule.maxLength', { count: this.phoneMaxLength }),
          v => (v && isValidPhoneNumber(v)) || this.$t('admin.sub.users.form.createDriver.input.phone.rule.valid')
        ],
        valid: true,
        loading: false
      };
    },
    computed: {
      show: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        }
      }
    },
    methods: {
      async createDriver () {
        this.loading = true;
        try {
          const response = await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'user/driver', {
            firstName: (this.firstName === null ? '' : this.firstName),
            lastName: (this.lastName === null ? '' : this.lastName),
            username: (this.username === null ? '' : this.username),
            phone: (this.phone === null ? '' : this.phone)
          });
          if (response.data !== undefined && response.data.password !== undefined) {
            alert('Le livreur devra utiliser l\'identifiant ' + String(this.username) + ' et le mot de passe ' + String(response.data.password));
          }
          this.$emit('success');
          this.show = false;
          this.$refs.form.reset();
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('admin.sub.users.form.createDriver.error.fail'));
        }
        this.loading = false;
      },
      autoCompleteUsername () {
        if (typeof this.username === 'string' && this.username.length === 0 && typeof this.firstName === 'string' && typeof this.lastName === 'string') {
          this.username = (this.firstName + '.' + this.lastName).toLowerCase().replace(/\s/g, '');
        }
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
