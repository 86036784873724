<template>
  <v-container id="edit-user-detail" class="m-0 pa-0">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('userSettings.title') }}
          </div>
        </template>
        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && editUserDetail()">
          <v-container class="edit-user-detail" style="max-width: 1200px">
            <v-row>
              <v-col cols="12" sm="6">
                <v-select v-model="settings.vehicle.value" :items="vehicles"
                          :label="$t('userSettings.settings.vehicle.name')" :loading="loading" :disabled="loading"
                          item-value="id" item-text="name" mandatory filled color="primary"
                />
                <label>
                  {{ $t('userSettings.settings.vehicle.values.' + settings.vehicle.value + '.description') }}
                </label>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="close()">
              {{ $t('userSettings.settings.vehicle.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && editUserDetail()">
              {{ $t('userSettings.settings.vehicle.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';

  export default {
    name: 'EditUserDetailForm',
    props: {
      user: {
        type: Object,
        default: undefined
      }
    },
    data () {
      return {
        valid: true,
        loading: false,
        settings: {
          vehicle: {
            id: undefined,
            name: 'vehicle',
            value: 'motorcycle'
          }
        },
        vehicles: [
          { id: 'trainingDistance', name: this.$t('userSettings.settings.vehicle.values.trainingDistance.name') },
          { id: 'training', name: this.$t('userSettings.settings.vehicle.values.training.name') },
          { id: 'trainingDistance2', name: this.$t('userSettings.settings.vehicle.values.trainingDistance2.name') },
          { id: 'training2', name: this.$t('userSettings.settings.vehicle.values.training2.name') },
          { id: 'bike', name: this.$t('userSettings.settings.vehicle.values.bike.name') },
          { id: 'scooter', name: this.$t('userSettings.settings.vehicle.values.scooter.name') },
          { id: 'lightmotorcycle', name: this.$t('userSettings.settings.vehicle.values.lightmotorcycle.name') },
          { id: 'motorcycle', name: this.$t('userSettings.settings.vehicle.values.motorcycle.name') },
          { id: 'car', name: this.$t('userSettings.settings.vehicle.values.car.name') }
        ]
      };
    },
    computed: {
      show: {
        get () {
          return this.user !== undefined;
        }
      }
    },
    watch: {
      user: function () {
        this.refreshData();
      }
    },
    mounted () {
      this.refreshData();
    },
    methods: {
      close () {
        this.$emit('success');
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'user/settings?userId=' + this.user.id);
          if (response.data !== undefined && response.data.settings !== undefined) {
            response.data.settings.forEach((setting) => {
              switch (setting.name) {
                case 'vehicle':
                  this.settings.vehicle.id = setting.id;
                  this.settings.vehicle.name = setting.name;
                  this.settings.vehicle.value = setting.value;
                  break;
              }
            });
          }
        } catch (error) {
          this.$emit('fail', error, await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('userSettings.error.fail')));
        }
        this.loading = false;
      },
      async editUserDetail () {
        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'admin/user/settings', {
            userId: this.user.id,
            settings: [
              { id: this.settings.vehicle.id, name: this.settings.vehicle.name, value: this.settings.vehicle.value }
            ]
          });
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('userSettings.error.fail')));
        }
        this.loading = false;
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
